//colors shade
$white: #ffffff;
$color_bon_jour_approx: #e0e0e0;
$color_gray_nurse_approx: #e8e8e8;
$color_alto_approx: #dddddd;
$color_gallery_approx: #eee;
$color_cararra_approx: rgba(236, 236, 236, 1);
$color_zircon_approx: #f5fcff;
$light-gray: #f9f9f9;
$color_wild_sand_approx: #f5f4f4;
$color_bon_jour_60_approx: rgba(224, 224, 224, 0.6);
$color_cararra_60_approx: rgba(236, 236, 236, 0.6);
$color_mountain_mist_approx: #999999;
$color_silver_chalice_approx: #aaaaaa;
$color_cararra_11_approx: rgba(236, 236, 236, 0.11);
$color_mine_shaft_approx: #333;
$color_mine_shaft_40_approx: rgba(51, 51, 51, 0.4);
$black_4: rgba(0, 0, 0, 0.04);
$black_11: rgba(0, 0, 0, 0.11);
$black_10: rgba(0, 0, 0, 0.10);
$black_17: rgba(0, 0, 0, 0.17);
$black: #000;
$theme-gray: #586082;
$color_saffron_approx: #eeb829;

//fonts
$font_0: Lato;
$font_1: sans-serif;

//urls
$url_0: url(../../images/screen1.png);
$url_1: url(../../images/testimonial.jpg);


//Color settings
$primary_color: #5f57ea;
$secondary_color: #9647db;
$bg_gradiant : linear-gradient(to right,$primary_color 0%, $secondary_color 100%);
$color_bay_of_many_45_approx :rgba( $primary_color, .45 );



